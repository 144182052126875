<template>
  <div>
    <v-app-bar
      class="app-bar"
      :height="APP_HEADER_HEIGHT"
      :style="{ borderColor }"
      tile
      flat
    >
      <v-container class="d-flex align-center flex-nowrap">
        <v-btn
          icon="ui-icon-menu"
          class="mr-7 hide-on-small-size"
          @click.stop="drawer = !drawer"
        />

        <v-img
          class="cursor-pointer flex-grow-0"
          alt="Премия ПроДокторов"
          width="auto"
          position="left"
          height="40"
          :src="imageSrc"
          @click="onLogoClick"
        />
        <nav class="ml-auto gc-8 d-flex hide-on-large-size">
          <NuxtLink
            v-for="item in menuItems"
            :key="item.name"
            class="text-decoration-none"
            :class="isActiveLink(item.to) ? linksClasses.exactActive : linksClasses.inactive"
            :to="item.to"
          >
            {{ item.name }}
          </NuxtLink>
        </nav>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      location="left"
      temporary
    >
      <v-list>
        <v-list-item
          v-for="(item, index) in drawerItems"
          v-bind="item"
          :key="index"
          class="py-2"
          :active="item.to === $route.fullPath || (item.to as Partial<RouteLocation>).name === $route.name"
          exact
        >
          <v-list-item-title class="text-ui-kit-text text-decoration-none">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'
import { useTheme } from 'vuetify'
import type { RouteLocation } from 'vue-router'
import { APP_HEADER_HEIGHT } from '~/constants'
import { AWARD_ROUTE_NAMES, GRAND_PRIX_ROUTE_NAMES } from '~/features/Home/constants'

const props = defineProps({
  underline: { type: Boolean, default: false },
})

const drawer = ref(false)

const windowScroll = useWindowScroll()

const $route = useRoute()
const $router = useRouter()

const mainStore = useMainStore()
const theme = useTheme()

const imageSrc = computed(() => theme.current.value.dark
  ? '/graphics/logo-dark.svg'
  : '/graphics/logo-light.svg')

const scrolled = computed(() => windowScroll.y.value > 0)

const menuItems = computed(() => ([
  {
    name: 'Результаты премии',
    to: { name: AWARD_ROUTE_NAMES.main, params: { year: mainStore.awardYears[0] } },
  },
  {
    name: 'Результаты Гран-при',
    to: { name: GRAND_PRIX_ROUTE_NAMES.doctor, params: { year: mainStore.grandPrixYears[0] } },
  },
  {
    name: 'Как это было',
    to: { name: 'index', hash: '#how-it-was' },
  },
]))

const drawerItems = computed(() => [{ name: 'Главная', to: '/' }, ...menuItems.value])

const borderColor = computed(() => {
  if (theme.current.value.dark) {
    return theme.themes.value.dark.colors['ui-kit-bg-gray-40'] + '!important'
  }

  if (!scrolled.value && !props.underline) {
    return 'transparent' + '!important'
  }

  return theme.themes.value.light.colors['ui-kit-bg-gray-60'] + ' !important'
})

const linksClasses = computed(() => theme.current.value.dark
  ? {
      exactActive: 'text-ui-kit-disabled',
      inactive: 'text-ui-kit-text',
    }
  : {
      exactActive: 'text-ui-kit-text-info',
      inactive: 'text-ui-kit-text-secondary',
    })

function isActiveLink(link: { name: string, hash?: string }) {
  return $route.name?.toString().includes(link.name) && (!link.hash || $route.hash === link.hash)
}

function onLogoClick() {
  if ($route.name === 'index') {
    return scrollToPosition()
  }

  $router.push({ name: 'index' })
}
</script>

<style lang="scss" scoped>
.app-bar {
  border-bottom: 1px solid !important;
}

.hide-on-small-size {
  @media (min-width: 780px) {
    display: none !important;
  }
}

.hide-on-large-size {
  @media (max-width: 780px) {
    display: none !important;
  }
}
</style>
